// @flow

import classnames from 'classnames';
import React from 'react';
import Section from '@latitude/section';
import Button from '../Button/Button';

import './_hero.scss';

type Props = {
  className?: string,
  heading: React.Node,
  subHeading?: React.Node,
  intro: React.Node,
  image: React.Node,
  cta: React.node
};

const Hero = (props: Props) => (
  <div className="page-banner">
    <Section
      backgroundImage={props.backgroundImage}
      className={classnames(
        'page-banner page-banner--no-padding hero bg-blue hero-low-rate-personal-loan',
        props.className
      )}
    >
      <div className="d-flex flex-row flex-wrap">
        <div className="d-flex flex-column" style={{ flex: '1 1 60%' }}>
          <h1 className="hero__heading text--invert">{props.heading}</h1>
          {props.subHeading && (
            <div className="hero__subheading text--invert">
              {props.subHeading}
            </div>
          )}

          <p
            className="text--invert hero__intro"
            dangerouslySetInnerHTML={{ __html: props.intro }}
          />

          {props.introIcons && (
            <div className="hero__intro-icons">{props.introIcons}</div>
          )}

          {!props.cta ? (
            <div className="hero__intro">
              <div className="hero__cta">
                <div className="row">
                  <div className="col-md-5 apply-btn">
                    <Button
                      href="/loans/get-started.html"
                      primary
                      trackId="apply-now"
                    >
                      Apply Now
                    </Button>
                  </div>
                  {props.findOutMore && (
                    <div className="col-md-5">
                      <Button {...props.findOutMore} tertiary>
                        Find out more
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            props.cta
          )}
        </div>
        <div
          className="d-none d-lg-block align-self-end"
          style={{ flex: '1 1 40%' }}
        >
          {props.image}
        </div>
      </div>
    </Section>
  </div>
);

export default Hero;
